import * as React from "react";
import {Link} from "gatsby";
import styled from "styled-components";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import {Helmet} from 'react-helmet';
import "../static/default-styles.css"
import "normalize.css";

import {PlayPauseButton} from "../components/audioPlayer";
import VideoBG from "../components/videoBG";
import Timer from "../components/timer";

import favicon from "../images/favicon.ico";
import socialShareImage from "../images/can-i-force-you-to-meditate-social-image.jpeg";

import bgPoster from "../images/endless-waves-poster.jpg";
import endlessWaves from "../videos/endless-waves.gif";


////////////////////////
//     STYLES
////////////////////////

const mainColor = "#E96C80";





////////////////////////
//     CONTENT
////////////////////////

const title = "Can I force you to meditate?";

const htmlMetaDescription = "A neuroscientist and a musician walk into a bar. This is what they created.";

let headlineH1 = title;

const info = [
  "For 30 years we've studied how music affects the brain.",
  "Got grants from NASA and the NIH",
  "And even wrote the book on audio neuroscience.",
  "Now it's time for some fun."
]

const instructions = [
"You'll need headphones",
"Then you'll hit play",
"For a fair try, once you hit play close your eyes and sit still for at least 30 seconds"
]


 



////////////////////////
//     FUNCTIONS
////////////////////////
class Home extends React.Component {
  constructor(props) {
    super(props); 

    this.state = {}
    this.audioPlayer = React.createRef();
    
    this.state = {
      headlineH1: headlineH1,
      instructionsProgress: 0,
      timerIsActive: false,
      timerIsPaused: false,
      timeElapsed: 0,
      playerStarted: false,
      audioFile: null
    }

  }

  componentDidMount(){
    this.setState({
      timerIsActive: true,
      timerIsPaused: false
    });


  }

  checkTimer(){

    if (this.state.timerIsActive && this.state.timerIsPaused === false ) {
      let interval = setInterval(() => {
        this.setState({timeElapsed: this.state.timeElapsed + 100});
      }, 100);

      this.setState({timerIntervalId: interval})
    } else {
      clearInterval(this.state.timerIntervalId);
    }
  }
  
  
  handlePauseResume = () => {
    this.setState({timerIsPaused: !this.state.timerIsPaused});
    this.checkTimer();
  };
  
  handleReset = () => {
    this.setState({
      timerIsActive: false,
      timeElapsed: 0
    });
    this.checkTimer();
  };

  track = (props={type: "ViewContent", value: 0}) => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        const trackType = props.type;
        const value = props.value;
        const contentName = props.content_name;
        const contentType = props.content_type;


        let passedProps = {currency: "USD"};
        if(value){passedProps.value = value};
        if(contentName){passedProps.content_name = contentName};
        if(contentType){passedProps.content_type = contentType};

        window.fbq('track', trackType, passedProps);
      }
    }
  }


  render(){
    return(
      <main>
        {/* REACT HELMET (to adjust metadata) */}
        <Helmet>
          <meta name="icon" href={favicon} />
          <link rel="icon" type="image/ico" href={favicon} />
          <title>{title}</title>
              <meta name="description"     content={htmlMetaDescription} />

              <meta name="facebook-domain-verification" content="nh4b7fjtmu7ueff5fn9a0gjkah7sk4" />
              <meta property="og:url"                content={"https://www.caniforceyoutomeditate.com"} />
              <meta property="og:type"               content="website" />
              <meta property="og:title"              content={title} />
              <meta property="og:description"        content={htmlMetaDescription} />

              <meta property="og:image"              content={socialShareImage} />
              <meta property="og:image:width"        content="940" />
              <meta property="og:image:height"        content="788" />
        </Helmet>

        <VideoBG start={this.state.playerStarted} play={this.state.isPlaying} src={endlessWaves} srcType="gif" poster={bgPoster} />
        
        <MainWrapper>

          {!this.state.playerStarted &&

            <SubWrapper>
              {/* HEADLINE */}
              {this.state.playerStarted ? <></> : this.state.instructionsProgress === instructions.length ? <><MainHeadline>{title}</MainHeadline> <p>Let's find out. When ready, tap play</p></> : <MainHeadline>{title}</MainHeadline>}


              {/* INFO */}
              {this.state.screen !== "challenge" && this.state.instructionsProgress !== instructions.length &&
                <InfoWrapper>
                  {info.map((sentence, index) => {
                    return(<InfoSentence key={index} >{sentence}</InfoSentence>)
                  })}
                </InfoWrapper>
              }


              {/* INSTUCTIONS*/}
              {this.state.screen === "challenge" ? 
                <>
                  {this.state.instructionsProgress < instructions.length && 
                    <h4 id="main-headline">Step {this.state.instructionsProgress + 1} of {instructions.length + 1}: {instructions[this.state.instructionsProgress]}</h4>
                  }
                </> 

                : <StartTextButton onClick={() => this.setState({screen: "challenge"})}>Click here to find out if I can force you to meditate...</StartTextButton>
              }


              {/* INSTUCTION BUTTONS */}
              {(this.state.screen === "challenge" && this.state.instructionsProgress !== instructions.length) &&
                <>
                  <ButtonContainer>
                    {this.state.instructionsProgress > 0 ? <BackButton className="button" onClick={() => this.setState({instructionsProgress: this.state.instructionsProgress - 1})}>Back</BackButton> : <></>} 
                    <ForwardButton className="button" onClick={() => this.setState({instructionsProgress: this.state.instructionsProgress + 1})}>Continue</ForwardButton>
                  </ButtonContainer>
                </>
              }



            </SubWrapper>
          }

          {/* TEXT SHOWN WHILE WATCHING PLAYER */}
          {this.state.playerStarted &&
            <p>
              {this.state.isPlaying 
                ? <>Close your eyes and relax</>
                : <>You're not quitting on me, are you?</>
              }
            </p>
          }

          {/* TIMER */}
          {this.state.playerStarted && 
            <Timer time={this.state.timeElapsed}/>
          }


          {this.state.screen === "challenge" && (this.state.instructionsProgress === instructions.length) &&
              <>
                <PlayPauseButton 
                  self={this} 
                  audio={this.state.audioFile} 
                  color={mainColor}  
                  timerPauseResume = {this.handlePauseResume}
                  timerReset = {this.handleReset}
                />
              </>
          }

          

          {/* LEAD GEN - Shows only when someone has listened for 25 seconds */}
          {this.state.playerStarted && this.state.timeElapsed > 100 &&
            <LeadGenWrapper>
              <p style={{marginTop: 0}}><strong>Like our work? Learn more about us.</strong></p>
              <OutboundLink onClick={() => this.track({content_name: "Zenstant Manifesto", value: 0})} target="_blank" href="https://www.zenstant.co/manifesto" style={{color:"#0000ff"}} >[Manifesto]: We are the elite performers. The producers. The doers.</OutboundLink>
              <br/><em>(Opens in new page, won't stop music)</em>
            </LeadGenWrapper>
          }
        </MainWrapper>
      </main>
    )
  }

}


////////////////////////
//       STYLES
////////////////////////

const semiTransparentBgColor = "#eeeeee66";

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  // justify-content: center;
  // width: 100vw;
  // height:90vh;
  flex-direction: column;
  zIndex: 10;
  position: relative;
  top: 25vh;
  box-sizing: border-box;
  
`

const SubWrapper = styled.div`
  box-sizing: border-box;
  padding: 1.25rem 1rem;
  background-color: ${semiTransparentBgColor};
  max-width: 90vw;
  width: 600px;
  margin-bottom: 2rem;
`

const MainHeadline = styled.h1`
  font-size: 2rem;
  margin: 0.25rem 0;

`

const BeachBgImg = styled.img`
  width: 100vw;
  min-height: 100vw;
  position: absolute;
  top: 0;
  left: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  & .button:first-child{
    margin-right:1rem;
  }
`

const buttonStyles = `
  border: none;
  padding: 0.525rem 1.25rem 0.5rem;
  border-radius: 3px;
  background-color: #F5F0DC;
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
`

const StartTextButton = styled.button`
  border: none;
  color: #0000ff;
  text-decoration: underline;
  background-color: #ffffff00;
  cursor: pointer;
`

const ForwardButton = styled.button`
  ${buttonStyles};
  background-color: ${mainColor}
`

const BackButton = styled.button`
  ${buttonStyles};
`

const InfoWrapper = styled.div`
  text-align: left;
  padding: 0.25rem;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
`

const InfoSentence = styled.p`
  font-size: 1.125rem;
  // line-height: 1.1 rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`

const LeadGenWrapper = styled.div`
  background-color: ${semiTransparentBgColor};
  padding: 0.75rem;
  margin-top: 2rem;
  box-sizing: border-box;
  max-width: 90vw;
  @media (min-width: 768px) {
    max-width: 60vw;
  }
`

export default Home;
