import * as React from "react";
import hardZen from "../static/hard-zen-10-min-demo.mp3";
import headphones from "../static/images/headphones.svg";
import loadingRipple from "../images/loading-ripple.svg";


// Functions to create an audio player, play/pause button, and handle audio events
	
// HOW TO USE
	// initialize state of fileName to the name of the file to play (as a string), EG "electrofocus-demo-5min.mp3"
	// place `  <PlayPauseButton self={this} audio={"hard-zen-10-min-demo.mp3"} />}  ` wherever you want to have the audio player


const buttonColor = "#E96C80";  // NOTE! This is a poorly hard-coded solution to solve my problem of passing color into the styles (near the bottom of this code). Need to fix.



export const PlayPauseButton = (props) => {

	return(
		<>	
			<button style={(props.self.state.isPlaying ? pauseButton : playButton )} className = {(props.self.state.loading ? "audio-loading" : "")} 
  	              onClick={evt => {
  	                  evt.stopPropagation(); // avoid double-calling this event
  	                  evt.preventDefault();
											if (!props.self.state.audioLoaded){
												props.self.setState({loading: true, audioLoaded: false}); 
												loadMedia(props, props.self)
											};
											if(props.timerPauseResume){props.timerPauseResume()};
  	                  playPause(props.self, props);
  	                }}
  	            >
  	            {props.self.state.loading && <img src={loadingRipple} style={{position:"absolute", width:"6rem", height:"6rem", maxWidth: "6rem", left: "-6rem",top: "-3rem", opacity: "0.3"}}></img>}
			</button>

			{props.includeHeadphones && 
			<div className="" style={{display:"inline-flex", justifyContent:"center", alignItems:"center", marginTop:"5vh", flexDirection: "column" }}>
              <img src={headphones} style={{height:"3rem"}} />
              <p style={{marginBottom: "0", paddingLeft:"0.325rem", fontSize:"100%", textShadow: "0 0 8px #fff"}} >
                <em>Headphones greatly improve the effects</em>
              </p>
            </div>
        	}

			{AudioTag(props.self)}
		</>
	)
}




const playPause = (self, props) => {
	console.log("playPause function fired correctly");

	try{
		    if (self.audioPlayer.current.paused) {
		      console.log("Audio should be playing");
	
		      self.audioPlayer.current.play();

		      self.setState({        // set state
		        isPlaying: true,     // of playing to true 
		        playerStarted: true,  // and player started to true
						loading:false 		 // if this function fires then the audio has loaded correctly.

		      });
	
	
		    } else {
		      self.audioPlayer.current.pause();
		      self.setState({ isPlaying: false });
		    }
		}
		catch {

			console.log("loading failed");
			if (self.state.playAttempts < 4 || !self.state.playAttempts){ // If user pushes play too fast they throw an error... attempt to handle the error 3 times
				if (!self.state.playAttempts){self.setState({playAttempts: 0})};
				
				let totalAttempts = self.state.playAttempts +1;
				let waitTime = 100 * (2**(totalAttempts-1));
				self.setState({loading: true});

				console.log("Audio not loaded yet, retrying. Attempt #" + totalAttempts);
				self.setState({playAttempts: self.state.playAttempts + 1});
				setTimeout(() => {playPause(self); return;}, waitTime) // try again, waiting for a set time
			} else {self.setState({loading: false})}
		}
	};


const loadMedia = (props, self) => { // Grabs media when ready to avoid long download times
		let file;
		if(props.fileUrl){file = props.fileUrl}
		console.log("Audio is loading");
    fetch("https://d243blkzxfar6h.cloudfront.net/hard-zen-10-min-demo.mp3")
      .then(data => {
        self.setState({
        	audioFile: data.url,
        	audioLoaded: true,
        });
        return(data);
    })
    .catch(error => {console.log("error loading media is", error)})
}


const AudioTag = (self) => {
	return(
		<>
		{self.state.audioFile &&  // Only load audio player once component is mounted (see componentDidMount) aboce
		  	            
              <audio
                id="audioPlayer"
                crossOrigin="anonymous"
                ref={self.audioPlayer}
              >
                <source src={self.state.audioFile} type="audio/mpeg" /> 

              </audio>
        }
        </>
	)
}


const playButton = {
  margin: "0 auto",
  position: "relative",
  width: 0,
  height: 0,
  borderStyle: "solid",
  borderWidth: "3rem 0 3rem 5rem",
  borderColor: "transparent transparent transparent " + buttonColor,
  backgroundColor: "#ffffff00",
  padding: 0,
  cursor: "pointer",
  zIndex: 3,
}

const pauseButton = {
  borderStyle: "double",
  borderWidth: "0rem 0 0rem 6rem",
  height: "6rem",
  cursor: "pointer",
  borderColor: buttonColor,
  backgroundColor: "#ffffff00",
  padding: 0,
  margin: "0 auto",
  zIndex: 3,
  width:0,
}
